define("discourse/plugins/new-dir/discourse/new-dir-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route('newdirs', {
      resetNamespace: true
    });
  }
  ;
});