define("discourse/plugins/new-dir/discourse/initializers/newdirs", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'newdirs',
    initialize() {
      (0, _pluginApi.withPluginApi)('0.8.7', api => {
        api.decorateWidget('hamburger-menu:generalLinks', () => {
          return {
            href: '/newdirs',
            rawLabel: 'Directory'
          };
        });
      });
    }
  };
});