define("discourse/plugins/new-dir/discourse/controllers/plugins-newdirs", ["exports", "ember-addons/ember-computed-decorators"], function (_exports, _emberComputedDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = Ember.Controller.extend((_dec = (0, _emberComputedDecorators.observes)("newdirs.canLoadMore"), (_obj = {
    application: Ember.inject.controller(),
    _showFooter() {
      this.set("application.showFooter", !this.get("newdirs.canLoadMore"));
    },
    actions: {
      loadMore() {
        this.get('newdirs').loadMore();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "_showFooter", [_dec], Object.getOwnPropertyDescriptor(_obj, "_showFooter"), _obj)), _obj)));
});